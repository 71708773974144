import {useCallback, useRef} from 'react';

const DEFAULT_TIMEOUT = 100;

/**
 * Custom hook that debounces a function call. When invoked, it delays the execution
 * of the provided function by the specified timeout duration.
 *
 * @param func    The function to be debounced.
 * @param timeout The debounce timeout duration in milliseconds. If not provided, it defaults to the value of DEFAULT_TIMEOUT.
 * @returns A debounced version of the function.
 *
 * @example
 * ```tsx
 * // Define a function that will be debounced
 * const search = (query: string) => {
 *   // Perform search operation here
 * };
 *
 * // Use the useDebounce hook to create a debounced version of the search function
 * const debouncedSearch = useDebounce(search, 500);
 *
 * // Invoke the debounced function whenever necessary
 * debouncedSearch('apple');
 * debouncedSearch('banana');
 * // Only the last invocation will trigger the actual search operation after a delay.
 * ```
 */
export const useDebounce = (func: (...args: any[]) => void, timeout = DEFAULT_TIMEOUT) => {
    // eslint-disable-next-line no-undef
    const timer = useRef<NodeJS.Timeout>();

    return useCallback((...args: unknown[]) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => func(...args), timeout);
    }, [func, timeout]);
};