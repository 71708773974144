import {Container, Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import styled from 'styled-components';

import {H2, P} from 'Client/ui/components/layout/Text';

import {ContentAnimation, HeaderTransition, HeadlineTransition, LogoTransition} from 'Client/ui/animations/login';
import {OpacityAnimation} from 'Client/ui/animations/shared';
import {LoginAside} from 'Client/ui/pageLayouts/login/LoginAside';

import {Logo} from 'Client/ui/assets/images/icons';
import {iconAsBackground} from 'Client/ui/utils/helpers';

import type {WithChildren} from 'types/global';

interface ComponentProps {
    /** The test id. */
    testId: string;
}

/**
 * Login.
 *
 * @param props          Component props.
 * @param props.testId   The test id.
 * @param props.children The children.
 * @returns The component.
 */
const Login = ({children, testId}: WithChildren<ComponentProps>) => (
    <LoginWrapper data-cy={testId}>
        <LayoutWrap>
            <Content variants={ContentAnimation}>
                <Container as="section" isFluid>
                    <LogoWrapper>
                        <MotionLogo layoutId="Logo" transition={LogoTransition} />
                        <Spacer x={4} isInline isNotStretching />
                        <motion.div layoutId="LogoText" transition={LogoTransition}>
                            <P $isLight>Campaign Board</P>
                        </motion.div>
                    </LogoWrapper>
                    <Spacer y={8} isNotStretching />
                    <motion.div layoutId="Headline" transition={HeadlineTransition}>
                        <H2 as={motion.h1} variants={OpacityAnimation} $isLight layout>Willkommen zum</H2>
                    </motion.div>
                    {children}
                </Container>
            </Content>
            <LoginAside />
        </LayoutWrap>
        <Wrapper layoutId="Header" transition={HeaderTransition} />
    </LoginWrapper>
);

Login.displayName = 'Login';
Login.defaultProps = {testId: undefined};

export {Login};

const LoginWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const Wrapper = styled(motion.div)`
    background-color: ${({theme}) => theme.colors.headerBackgroundColor};
    height: 100vh;
    width: 100%;
`;

const LayoutWrap = styled.div`
    align-items: stretch;
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
`;

const Content = styled(motion.main)`
    display: flex;
    flex: 1 1 58%;
    flex-direction: column;
    justify-content: center;
`;

const LogoWrapper = styled.div`
    align-items: center;
    display: flex;
`;

const MotionLogo = styled(motion.div)`
    background-image: url(${iconAsBackground(Logo)});
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
`;