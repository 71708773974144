import type {TargetAndTransition, Variants} from 'framer-motion';

export const AsideAnimation: Variants = {
    enter: {
        transition: {
            delay: 0.5,
            duration: 0.5
        },
        x: '0%'
    },
    exit: {
        transition: {duration: 0.5},
        x: '100%'
    },
    initial: {x: '100%'}
};

export const ContentAnimation: Variants = {
    enter: {
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.2,
            staggerDirection: 1
        }
    },
    exit: {
        transition: {
            staggerChildren: 0.2,
            staggerDirection: -1
        }
    },
    initial: {}
};

export const HeaderTransition: TargetAndTransition['transition'] = {duration: 0.5};
export const HeadlineTransition: TargetAndTransition['transition'] = {duration: 0.5};
export const LogoTransition: TargetAndTransition['transition'] = {duration: 0.5};