import {useCallback, useEffect, useState} from 'react';

import {useDebounce} from 'Client/ui/hooks/useDebounce';

/**
 * Custom hook for managing a secret keyboard sequence.
 * This hook listens for a specific sequence of key presses ('N', 'F', 'Q') and opens a modal when the sequence is completed.
 * The sequence is reset if a key is pressed out of order or if 500ms elapse between key presses.
 *
 * @param openModal A function that opens the modal.
 *
 * @example
 * ```tsx
 * const { handleKeyDown } = useSecret(openModal);
 * ```
 */
export const useSecret = (openModal: () => void) => {
    const [secret, setSecret] = useState<string>('');
    /**
     * Reset the secret after 500ms.
     *
     * @returns Nothing.
     */
    const resetSecret = () => setSecret('');
    // eslint-disable-next-line @nfq/no-magic-numbers
    const debouncedReset = useDebounce(resetSecret, 500);

    /**
     * Callback function to handle keydown events.
     * This function updates the secret state based on the key pressed and opens the modal if the secret sequence is completed.
     *
     * @param event The keydown event.
     *
     * @example
     * ```tsx
     * window.addEventListener('keydown', handleKeyDown);
     * ```
     */
    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        const {key} = event;

        if (key === '.' && secret === '') {
            setSecret(oldSecret => oldSecret + key);
        } else if (key === 'Shift' && secret === '.') {
            return;
        } else if (key === 'N' && secret === '.') {
            setSecret(oldSecret => oldSecret + key);
        } else if (key === 'F' && secret === '.N') {
            setSecret(oldSecret => oldSecret + key);
        } else if (key === 'Q' && secret === '.NF') {
            openModal();
        } else {
            resetSecret();
        }

        debouncedReset();
    }, [secret, debouncedReset, openModal]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};