import React from 'react';

import {Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';
import Head from 'next/head';

import {H1} from 'Client/ui/components/layout/Text';
import {Secret} from 'Client/ui/components/secret/Secret';

import {OpacityAnimation} from 'Client/ui/animations/shared';
import {LoginModule} from 'Client/ui/modules/login/LoginModule';
import {Login as LoginLayout} from 'Client/ui/pageLayouts/Login';

import type {NextPageWithLayout} from 'types/global';

/**
 * Login Page.
 *
 * @returns The component.
 */
const Login: NextPageWithLayout = () => (
    <>
        <Head><title>O2 Campaign Board | Login</title></Head>
        <H1 as={motion.h2} variants={OpacityAnimation} $isLight>Campaign Board</H1>
        <Spacer y={10} isNotStretching />
        <LoginModule />
        <Secret />
    </>
);

/**
 * Gets the layout for this page.
 *
 * @param router        The router object from nextjs.
 * @param pageProps     All page props given from server.
 * @param PageComponent The page component to render.
 *
 * @returns The complete page.
 */
Login.getLayout = (router, pageProps, PageComponent) => (
    <LoginLayout>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <PageComponent router={router} {...pageProps} />
    </LoginLayout>
);

/**
 * Gets the layout key for the used layout.
 *
 * @returns The complete page.
 */
Login.getLayoutKey = () => 'Login';

export default Login;