import {m as motion} from 'framer-motion';
import Image from 'next/image';
import styled from 'styled-components';

import {AsideAnimation} from 'Client/ui/animations/login';

// eslint-disable-next-line import/extensions
import Logon from 'Client/ui/assets/images/LoginAside.jpg';

/**
 * LoginAside.
 *
 * @returns The component.
 */
const LoginAside = () => (
    <Aside variants={AsideAnimation}>
        <StyledImage
            alt="Login"
            placeholder="blur"
            sizes="50vw"
            src={Logon}
            fill
            priority
        />
    </Aside>
);

LoginAside.displayName = 'LoginAside';
LoginAside.defaultProps = {testId: undefined};

export {LoginAside};

const Aside = styled(motion.aside)`
    flex: 0 0 42%;
    max-width: 1920px;
    position: relative;
`;

const StyledImage = styled(Image)`
    object-fit: cover;
    object-position: center;
`;