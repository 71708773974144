import {authorize} from '@frontify/frontify-authenticator';
import {api, HTTP_METHODS} from '@nfq/typed-next-api';
import {di} from 'react-magnetic-di';

import type {LoginAdapter} from 'Client/domain/adapters/LoginAdapter';
import type {Token} from 'Client/domain/entities/login/Token';
import type {setAuthCookie, unsetAuthCookie} from 'src/pages/api/authenticate';

/**
 * LoginService class.
 *
 * This class implements the LoginAdapter interface and provides methods for authentication and login.
 * The 'authenticate' method is asynchronous and returns the access token after successful authentication.
 * The 'login' method is asynchronous and performs the login by sending the access token to the '/api/authenticate'
 * endpoint.
 *
 * @implements {LoginAdapter}
 */
class LoginService implements LoginAdapter {
    /**
     * Authenticates the user.
     *
     * @returns A Promise that resolves to the access token.
     *
     * @example
     * ```ts
     * const loginService = new LoginService();
     * const accessToken = await loginService.authenticate();
     * ```
     */
    async authenticate() {
        di(authorize);

        const authResponse = await authorize({
            clientId: process.env.NEXT_PUBLIC_FRONTIFY_CLIENT_ID,
            domain: process.env.NEXT_PUBLIC_FRONTIFY_DOMAIN,
            scopes: ['basic:read', 'basic:write']
        });

        return authResponse.bearerToken.accessToken;
    }

    /**
     * Performs the login.
     *
     * @param token The access token.
     *
     * @example
     * ```ts
     * const loginService = new LoginService();
     * const token = 'your_access_token';
     * ```
     */
    async login(token: Token) {
        await api<typeof setAuthCookie>('/api/authenticate', {
            body: {accessToken: token},
            method: HTTP_METHODS.POST
        });
    }

    /**
     * Asynchronous method to log out the user.
     * This method sends a DELETE request to the server to unset the authentication cookie, effectively logging out the user.
     *
     * @returns A promise that resolves when the logout operation is complete.
     *
     * @example
     * ```tsx
     * await LoginService.logout();
     * ```
     */
    async logout() {
        await api<typeof unsetAuthCookie>('/api/authenticate', {method: HTTP_METHODS.DELETE});
    }
}

export default new LoginService();